<template>
    <section class="new_css">
        <iframe class = "ifrommy":src="path" width="100%" style="height: 93vh;"></iframe>
    </section>
</template>
<script>
import util from "../../../../util/date";
import * as validate from "../../../../util/validate";
import {
        GetDocument,

} from "../../../api/hr";
import { getButtonList } from "../../../promissionRouter";
export default {
  data() {
    return {
      path:"", // 什么操作 新增，修改，解除
    };
  },
  computed:{
  },
  methods: {
    //获取列表
      getData(isCurrent = true) {
          let para = {
              type: '岗位及编制管理要求',
          };
          GetDocument(para).then((res) => {
              this.path = res.data.response.path;
              this.loading = false;
          });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
     
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
      this.buttonList = getButtonList(this.$route.path, routers, buttons);
      console.log("this.buttonList:", this.buttonList);
  },
};
</script>
<style scoped >
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>

